export class Defaults {
  public static applicationId = "bea2fb94-033c-11eb-adc1-0242ac120002";
  public static applicationToken = "832C0668DC76F03A945CEEE9CE5A2021C1D72100822069D4D17CA58698ABBE68913E164E5E77883E76ACC82A0A4C92736713A60CEB4A665AE261785EC900BF14";

  public static SERVER_MENU = "menu.bestelmandje.be/";
  // public static SERVER_MENU = "http://192.168.1.5:4200/";
  // public static SERVER_MENU = "http://localhost:4200/";

  public static SERVER_API = "https://api.bestelmandje.be/";
  // public static SERVER_API = "http://192.168.1.22:8443/";
  // public static SERVER_API = "http://localhost:8443/";

  public static SERVER_API_IMAGE = "https://storage.googleapis.com/imagestorage.bestelmandje.be/";
  // public static SERVER_API_IMAGE = "http://192.168.1.5:8080/";
}
