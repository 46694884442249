import {SupplementProductWrapper} from "./SupplementProductWrapper";
import {ProductSupplement} from "./ProductSupplement";

export class SupplementWrapper {

  public productSupplement: ProductSupplement;
  public supplementProductWrappers: SupplementProductWrapper[];

  public selectedProductId: string;
}
