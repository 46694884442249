import { Component, OnInit } from '@angular/core';
import {Defaults} from "../../constants/defaults";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.css']
})
export class TutorialComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
  }

  public openUrl(): void {
    const orderTicketId = this.route.snapshot.paramMap.get('orderTicketId');
    const qrClientToken = this.route.snapshot.paramMap.get('qrClientToken');

    this.router.navigateByUrl(orderTicketId + '/' + qrClientToken + '/na');
  }

}
