import {MyHttpHandlerComponent} from "./my-http-handler.component";

export abstract class IHaveMyHttpHandler {
  protected myHttpHandlerComponent: MyHttpHandlerComponent;

  public setMyHttpHandlerComponent(myHttpHandlerComponent: MyHttpHandlerComponent): void {
    this.myHttpHandlerComponent = myHttpHandlerComponent;

    this.init();
  }

  public abstract init(): void;

  public abstract justOpen(): void;

  public abstract justClose(): void;

}
