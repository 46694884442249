import {AbstractMenuComponent} from "../menu/abstract.menu.component";
import {Defaults} from "../../constants/defaults";
import {ProductOrderWrapper} from "../../domain/ProductOrderWrapper";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Product} from "../../domain/Product";
import {MenuVariantWrapper} from "../../domain/MenuVariantWrapper";
import {MenuVariantOptionWrapper} from "../../domain/MenuVariantOptionWrapper";
import {SupplementWrapper} from "../../domain/SupplementWrapper";
import {SupplementProductWrapper} from "../../domain/SupplementProductWrapper";
import {ProductCondimentWrapper} from "../../domain/ProductCondimentWrapper";
import {ProductExtraWrapper} from "../../domain/ProductExtraWrapper";
import {IHaveMyHttpHandler} from "../../handlers/http/my-http-handler/IHaveMyHttpHandler";
import {ConditionEnabledObject} from "../../domain/ConditionEnabledObject";
import {Supplement} from "../../domain/Supplement";
import {Router} from "@angular/router";
import {AbstractSelectSupplementComponent} from "../select-supplement/abstract-select-supplement.component";
import {ConditionUtility} from "../../handlers/ConditionUtility";

export abstract class AbstractProductDetailsComponent extends IHaveMyHttpHandler {

  public selectSupplementComponent: AbstractSelectSupplementComponent;

  private orderTicketId: string;
  private qrClientToken: string;

  public isOpen = false;

  public CONTENT_SERVER = Defaults.SERVER_API_IMAGE + 'qr/menu/product/image/';

  public productOrderWrapper: ProductOrderWrapper;

  private map = new Map<string, ConditionEnabledObject[]>();

  protected constructor(private http: HttpClient, private router: Router) {
    super();
  }

  public init(): void {
    this.getMenuComponent().setProductDetailsComponent(this);
  }

  protected abstract getMenuComponent(): AbstractMenuComponent;

  public open(orderTicketId: string, qrClientToken: string, product: Product): void {
    this.orderTicketId = orderTicketId;
    this.qrClientToken = qrClientToken;

    let productOrderWrapper = new ProductOrderWrapper();
    productOrderWrapper.product = product;
    productOrderWrapper.orderCount = 1;

    new Map<string, ConditionEnabledObject[]>();

    productOrderWrapper.productVariantWrappers = Array();
    for ( const productVariant of product.productVariants ) {
      let variantWrapper = new MenuVariantWrapper();
      variantWrapper.productVariant = productVariant;
      productOrderWrapper.productVariantWrappers.push(variantWrapper);

      let defaultFound = false;
      variantWrapper.optionWrappers = Array();
      for ( let variantOption of productVariant.variant.variantOptions ) {
        let variantOptionWrapper = new MenuVariantOptionWrapper();
        if ( variantOption.defaultOption == 'Y' && !defaultFound ) {
          variantOptionWrapper.selected = true;
          defaultFound = true;
        }
        else {
          variantOptionWrapper.selected = false;
        }
        variantOptionWrapper.variantOption = variantOption;
        variantWrapper.optionWrappers.push(variantOptionWrapper);

        if ( variantOption.similarOptionUniqueMaker != null && variantOption.similarOptionUniqueMaker != '' ) {
          if ( !this.map.has(variantOption.similarOptionUniqueMaker) ) {
            this.map.set(variantOption.similarOptionUniqueMaker, Array());
          }
          this.map.get(variantOption.similarOptionUniqueMaker).push(variantOption);
        }
      }
    }

    // console.log(productOrderWrapper);

    productOrderWrapper.productSupplementWrappers = Array();
    for (const productSupplement of product.productSupplements) {
      let supplementWrapper = new SupplementWrapper();
      supplementWrapper.productSupplement = productSupplement;
      productOrderWrapper.productSupplementWrappers.push(supplementWrapper);

      supplementWrapper.supplementProductWrappers = Array();
      supplementWrapper.selectedProductId = null;
      for (const supplementProduct of productSupplement.supplement.supplementProducts) {
        let supplementProductWrapper = new SupplementProductWrapper();
        supplementProductWrapper.supplementProduct = supplementProduct;
        supplementWrapper.supplementProductWrappers.push(supplementProductWrapper);

        if ( supplementProduct.defaultOption === 'Y' ) {
          supplementWrapper.selectedProductId = supplementProduct.productId;
        }
      }
    }

    productOrderWrapper.productCondimentWrappers = Array();
    for (const productCondiment of product.productCondiments) {
      let productCondimentWrapper = new ProductCondimentWrapper();
      productCondimentWrapper.value = (productCondiment.defaultOn || productCondiment.isRequired);
      productCondimentWrapper.productCondiment = productCondiment;
      productOrderWrapper.productCondimentWrappers.push(productCondimentWrapper);
    }

    productOrderWrapper.productExtraWrappers = Array();
    for (const productExtra of product.productExtras) {
      let productExtraWrapper = new ProductExtraWrapper();
      productExtraWrapper.value = false;
      productExtraWrapper.productExtra = productExtra;
      productOrderWrapper.productExtraWrappers.push(productExtraWrapper);
    }

    console.log(this.map);

    this.productOrderWrapper = productOrderWrapper;
    this.isOpen = true;
  }

  public close(reallyClose: boolean): void {
    if ( reallyClose && !this.router.navigateByUrl(this.orderTicketId + '/' + this.qrClientToken + '/na') ) {
      alert('Failed to open order list');
    }
    else {
      this.productOrderWrapper = null;
      this.isOpen = false;
    }
  }

  public submit(): void {
    if ( this.productOrderWrapper == null ) {
      alert("Er ging iets fout, herlaad aub de webpagina. Onze excuses voor het ongemak.");
    }

    const headers = new HttpHeaders({
      applicationId: Defaults.applicationId,
      applicationToken: Defaults.applicationToken,
      qrClientToken: this.qrClientToken
    });

    const observable = this.http.post(Defaults.SERVER_API + 'qr/order/addProduct/' + this.orderTicketId, this.productOrderWrapper, { headers });

    this.myHttpHandlerComponent.get(observable, (result: any) => {
      this.getMenuComponent().getOrderTicket();
      this.close(true);
    });
  }

  public calcPrice(supplement: Supplement, product: Product): number {
    if ( supplement.mode === 'F' || supplement.mode === 'R' ) {
      return 0;
    }
    else if ( supplement.mode === 'D' || supplement.mode === 'E' ) {
      if ( product.price >= supplement.price ) {
        return product.price - supplement.price;
      }
      else {
        return 0;
      }
    }
    else if ( supplement.mode === 'P' || supplement.mode === 'Q' ) {
      return product.price;
    }
  }

  public isAtLeastOneEnabledCondiment(productCondimentWrappers: ProductCondimentWrapper[], productOrderWrapper: ProductOrderWrapper): boolean {
    for ( const productCondimentWrapper of productCondimentWrappers ) {
      if ( ConditionUtility.isEnabled(productCondimentWrapper.productCondiment, productOrderWrapper) ) {
        return true;
      }
    }
    return false;
  }

  public isAtLeastOneEnabledExtra(productExtraWrappers: ProductExtraWrapper[], productOrderWrapper: ProductOrderWrapper): boolean {
    for ( const productExtraWrapper of productExtraWrappers ) {
      if ( ConditionUtility.isEnabled(productExtraWrapper.productExtra, productOrderWrapper) ) {
        return true;
      }
    }
    return false;
  }

  public isEnabled(conditionEnabledObject: ConditionEnabledObject, productOrderWrapper: ProductOrderWrapper): boolean {
    return ConditionUtility.isEnabled(conditionEnabledObject, productOrderWrapper);
  }

  public plus(): void {
    this.productOrderWrapper.orderCount = this.productOrderWrapper.orderCount + 1;
  }

  public min(): void {
    const orderCount = this.productOrderWrapper.orderCount - 1;
    this.productOrderWrapper.orderCount = orderCount > 0? orderCount: 1;
  }

  public isShown(extraWrapper: ProductExtraWrapper): boolean {
    const productExtra = extraWrapper.productExtra;

    if ( productExtra.condimentId == null ) {
      return true;
    }
  }


  justOpen() {
    this.isOpen = true;
  }

  justClose() {
    this.isOpen = false;
  }

  public setSelectSupplementComponent(selectSupplementComponent: AbstractSelectSupplementComponent): void {
    this.selectSupplementComponent = selectSupplementComponent;
  }
}
