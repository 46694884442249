import {AbstractDetailComponent} from "../detail/AbstractDetail.component";
import {Defaults} from "../../constants/defaults";
import {Table} from "../../domain/table";
import {Menu} from "../../domain/menu";
import {MenuDelivery} from "../../domain/MenuDelivery";
import {Category} from "../../domain/category";
import {MenuOrderTicket} from "../../domain/MenuOrderTicket";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {MenuTableObject} from "../../domain/MenuTableObject";
import {MenuHandler} from "../../handlers/MenuHandler";
import {Shop} from "../../domain/shop";
import {AbstractProductDetailsComponent} from "../product-details/abstract-product-details.component";
import {AbstractShopDetailsComponent} from "../shop-details/abstract-shop-details-component";
import {IHaveMyHttpHandler} from "../../handlers/http/my-http-handler/IHaveMyHttpHandler";
import {Product} from "../../domain/Product";

export abstract class AbstractMenuComponent extends IHaveMyHttpHandler {
  public shopDetailsComponent: AbstractShopDetailsComponent;
  public productDetailsComponent: AbstractProductDetailsComponent;
  public orderDetailComponent: AbstractDetailComponent

  public CONTENT_SERVER = Defaults.SERVER_API_IMAGE + 'qr/menu/product/image/';

  public orderTicketId: string;
  public qrClientToken: string;
  public menuState: string;

  title = 'menu';

  private container;

  public table: Table;
  public menu: Menu;
  public shop: Shop;
  public deliveryTownships: MenuDelivery[];

  public categories: Category[];

  public orderTicket: MenuOrderTicket;

  protected constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) {
    super();
  }

  public init(): void {
    this.orderTicketId = this.route.snapshot.paramMap.get('orderTicketId');
    this.qrClientToken = this.route.snapshot.paramMap.get('qrClientToken');
    this.menuState = this.route.snapshot.paramMap.get('menuState');

    this.container = document.getElementById("slideContainer");
    this.getMenu();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.menuState = this.route.snapshot.paramMap.get('menuState');
        this.checkPopupStates();
      }
    });
  }

  private getMenu(): void {
    const headers = new HttpHeaders({
      applicationId: Defaults.applicationId,
      applicationToken: Defaults.applicationToken,
      qrClientToken: this.qrClientToken
    });

    this.myHttpHandlerComponent.get<MenuTableObject>(this.http.get<MenuTableObject>(Defaults.SERVER_API + 'qr/menu/byOrderTicketId/' + this.orderTicketId, {headers}), (result: MenuTableObject) => {
      this.setTheme(result.menu);
      this.table = result.table;
      this.menu = result.menu;
      this.shop = result.shop;
      this.deliveryTownships = result.deliveryTownships;
      this.categories = MenuHandler.linkRelations(this.menu);

      console.info(result.shop.shopClosureObject); // TODO tsavran delete

      this.doDeviceSpecificStuff();
      this.getOrderTicket();
    });
  }

  private checkPopupStates(): void {
    this.productDetailsComponent.close(false);
    this.orderDetailComponent.close(false);
    this.shopDetailsComponent.close(false);

    if ( this.menuState === 'na' ) {

    }
    else if ( this.menuState === 'basket' ) {
      this.orderDetailComponent.open(this.orderTicketId, this.qrClientToken, this.deliveryTownships, this.orderTicket, this.shop, this.menu);
    }
    else if ( this.menuState === 'shop-detail' ) {
      this.shopDetailsComponent.open(this.orderTicketId, this.qrClientToken);
    }
    else {
      for ( const category of this.menu.categories ) {
        for ( const product of category.products ) {
          if ( product.id === this.menuState ) {
            this.productDetailsComponent.open(this.orderTicketId, this.qrClientToken, product);
            return;
          }
        }
      }
    }
  }

  protected abstract doDeviceSpecificStuff(): void;

  private setTheme(menu: Menu): void {
    document.documentElement.style.setProperty('--backgroundColor', menu.backgroundColor);
    document.documentElement.style.setProperty('--headerColor', menu.headerColor);
    document.documentElement.style.setProperty('--headerTitleColor', menu.headerTitleColor);
    document.documentElement.style.setProperty('--categoryNameColor', menu.categoryNameColor);
    document.documentElement.style.setProperty('--selectedCategoryNameColor', menu.selectedCategoryNameColor);
    document.documentElement.style.setProperty('--productNameColor', menu.productNameColor);
    document.documentElement.style.setProperty('--productDescriptionColor', menu.productDescriptionColor);
    document.documentElement.style.setProperty('--productPriceColor', menu.productPriceColor);
    document.documentElement.style.setProperty('--productSelectionColor', menu.productSelectionColor);
    document.documentElement.style.setProperty('--productBorderColor', menu.productBorderColor);
    document.documentElement.style.setProperty('--productBorderSize', menu.productBorderSize + 'px');
    document.documentElement.style.setProperty('--productShadowColor', menu.productShadowColor);
    document.documentElement.style.setProperty('--productShadowSize', menu.productShadowSize + 'px');
    document.documentElement.style.setProperty('--productShadowYOffset', menu.productShadowYOffset + 'px');
    document.documentElement.style.setProperty('--productShadowXOffset', menu.productShadowXOffset + 'px');
    document.documentElement.style.setProperty('--productBackgroundColor', menu.productBackgroundColor);
    document.documentElement.style.setProperty('--popupBackgroundColor', menu.popupBackgroundColor);
    document.documentElement.style.setProperty('--popupBorderColor', menu.popupBorderColor);
    document.documentElement.style.setProperty('--popupBorderSize', menu.popupBorderSize + 'px');
    document.documentElement.style.setProperty('--popupShadowColor', menu.popupShadowColor);
    document.documentElement.style.setProperty('--popupShadowSize', menu.popupShadowSize + 'px');
    document.documentElement.style.setProperty('--popupShadowXOffset', menu.popupShadowXOffset + 'px');
    document.documentElement.style.setProperty('--popupShadowYOffset', menu.popupShadowYOffset + 'px');
    document.documentElement.style.setProperty('--popupButtonColor', menu.popupButtonColor);
    document.documentElement.style.setProperty('--textColorLight', menu.textColorLight);
    document.documentElement.style.setProperty('--textColorDark', menu.textColorDark);
  }

  public getOrderTicket(): void {
    const phone = this.orderTicket == null ? null : this.orderTicket.phone;
    const email = this.orderTicket == null ? null : this.orderTicket.email;
    const menuDeliveryId = this.orderTicket == null ? null : this.orderTicket.menuDeliveryId;
    const streetAndNr = this.orderTicket == null ? null : this.orderTicket.streetAndNr;

    const headers = new HttpHeaders({
      applicationId: Defaults.applicationId,
      applicationToken: Defaults.applicationToken,
      qrClientToken: this.qrClientToken
    });

    this.myHttpHandlerComponent.get<MenuOrderTicket>(this.http.get<MenuOrderTicket>(Defaults.SERVER_API + 'qr/order/myTicket/' + this.orderTicketId, {headers}), (result: MenuOrderTicket) => {
      this.orderTicket = result;
      this.orderTicket.phone = phone;
      this.orderTicket.email = email;
      this.orderTicket.menuDeliveryId = menuDeliveryId;
      this.orderTicket.streetAndNr = streetAndNr;

      MenuHandler.linkOrders(this.menu, this.orderTicket);
      this.orderDetailComponent.setOrderTicket(this.orderTicket);
      this.checkPopupStates();
    });
  }

  public setShopDetailsComponent(shopDetailsComponent: AbstractShopDetailsComponent): void {
    this.shopDetailsComponent = shopDetailsComponent;
  }

  public setProductDetailsComponent(productDetailsComponent: AbstractProductDetailsComponent): void {
    this.productDetailsComponent = productDetailsComponent;
  }

  public setOrderDetailComponent(detailComponent: AbstractDetailComponent): void {
    this.orderDetailComponent = detailComponent;
  }

  public openProductDetail(product: Product): void {
    if ( !this.router.navigateByUrl(this.orderTicketId + '/' + this.qrClientToken + '/' + product.id) ) {
      alert('Failed to open product details');
    }
  }

  public openBasket(): void {
    if ( !this.router.navigateByUrl(this.orderTicketId + '/' + this.qrClientToken + '/basket') ) {
      alert('Failed to open order list');
    }
  }

  public openShopDetail(): void {
    if ( !this.router.navigateByUrl(this.orderTicketId + '/' + this.qrClientToken + '/shop-detail') ) {
      alert('Failed to open order list');
    }
  }

  public getOrderItemCount(): number {
    if (this.orderTicket == null) {
      return 0;
    }

    let count = 0;

    for (const orderItem of this.orderTicket.orderItems) {
      count += orderItem.orderCount;
    }

    return count;
  }
}
