import {Component, Input, OnInit} from '@angular/core';
import {ErrorShowingComponent} from "./ErrorShowingComponent";

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css']
})
export class ErrorDialogComponent implements OnInit {

  @Input() errorShowingComponent: ErrorShowingComponent;

  public errorMessage: string;

  public isOpen = false;

  constructor() { }

  ngOnInit(): void {
    this.errorShowingComponent.setErrorDialogComponent(this);
  }

  public showError(source: string, errorMessage: string, errorDetails: string[]): void {
    this.errorMessage = errorMessage;

    this.isOpen = true;
  }

  public close(): void {
    this.errorMessage = null;

    this.isOpen = false;
    this.errorShowingComponent.justOpen();
  }

}
