import {ConditionEnabledObject} from "../domain/ConditionEnabledObject";
import {ProductOrderWrapper} from "../domain/ProductOrderWrapper";

export class ConditionUtility {

  public static isEnabled(conditionEnabledObject: ConditionEnabledObject, productOrderWrapper: ProductOrderWrapper): boolean {
    if ( conditionEnabledObject.conditionEnabled !== 'Y' ) {
      return true;
    }

    const conditions = conditionEnabledObject.enablingCondition;

    for ( const variantWrapper of productOrderWrapper.productVariantWrappers ) {
      for ( const optionWrapper of variantWrapper.optionWrappers ) {
        if ( optionWrapper.variantOption.conditionKey != '' && conditions.includes(optionWrapper.variantOption.conditionKey) && ConditionUtility.isEnabled(optionWrapper.variantOption, productOrderWrapper) && optionWrapper.selected ) {
          return true;
        }
      }
    }

    for ( const productSupplementWrapper of productOrderWrapper.productSupplementWrappers ) {
      for ( const supplementProduct of productSupplementWrapper.productSupplement.supplement.supplementProducts ) {
        if ( supplementProduct.conditionKey != '' && conditions.includes(supplementProduct.conditionKey) && ConditionUtility.isEnabled(supplementProduct, productOrderWrapper) && supplementProduct.productId === productSupplementWrapper.selectedProductId ) {
          return true;
        }
      }
    }

    for ( const productCondimentWrapper of productOrderWrapper.productCondimentWrappers ) {
      if ( productCondimentWrapper.productCondiment.condiment.conditionKey != '' && conditions.includes(productCondimentWrapper.productCondiment.condiment.conditionKey) && ConditionUtility.isEnabled(productCondimentWrapper.productCondiment, productOrderWrapper) ) {
        return true;
      }
    }

    for ( const productExtraWrapper of productOrderWrapper.productExtraWrappers ) {
      if ( productExtraWrapper.productExtra.condiment.conditionKey != '' && conditions.includes(productExtraWrapper.productExtra.condiment.conditionKey) && ConditionUtility.isEnabled(productExtraWrapper.productExtra, productOrderWrapper) ) {
        return true;
      }
    }

    return false;
  }
}
