import {Component, Input, OnInit} from '@angular/core';
import {AbstractShopDetailsComponent} from "../abstract-shop-details-component";
import {AbstractMenuComponent} from "../../menu/abstract.menu.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-shop-details-mobile',
  templateUrl: './shop-details-mobile.component.html',
  styleUrls: ['./shop-details-mobile.component.css']
})
export class ShopDetailsMobileComponent extends AbstractShopDetailsComponent implements OnInit {
  @Input() menuComponent: AbstractMenuComponent;

  constructor(router: Router) {
    super(router);
  }

  ngOnInit(): void {
    super.onInit();
  }

  protected getMenuComponent(): AbstractMenuComponent {
    return this.menuComponent;
  }

}
