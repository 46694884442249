import {AbstractMenuComponent} from "../abstract.menu.component";
import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-order-menu-desktop',
  templateUrl: './desktop.menu.component.html',
  styleUrls: ['./desktop.menu.component.css']
})
export class DesktopMenuComponent extends AbstractMenuComponent {

  constructor(route: ActivatedRoute, router: Router, http: HttpClient) {
    super(route, router, http);
  }

  public init(): void {
    super.init();
  }

  protected doDeviceSpecificStuff(): void {
  }

  justClose(): void {
  }

  justOpen(): void {
  }


}
