<div class="product-popup-box" [ngClass]="{'popup-open': isOpen}">
  <div class="popup-header">
    <div class="popup-header-back-container">
      <img (click)="close()" src="../../../assets/icons/arrow_back_black_24dp.svg">
    </div>
  </div>

  <div class="popup-body">
    <div class="full">
      <h3 class="bestelmandje-title">Kies hier een gemeente</h3>
    </div>

    <div class="full">

      <ng-container *ngIf="menuDeliveries != null">
        <ng-container *ngFor="let delivery of menuDeliveries">
          <div class="product-size-box" [ngClass]="{'supplement-selected': orderTicket.menuDeliveryId === delivery.id}" (click)="selectDelivery(delivery)">
            <div class="product-details">
              <h3 class="product-name text-one-line"><ng-container *ngIf="delivery.details != null && delivery.details.length > 0">{{delivery.details[0].name}}</ng-container></h3>
              <p><span [ngClass]="{'strike': !delivery.monday}">Ma</span>, <span [ngClass]="{'strike': !delivery.tuesday}">Di</span>, <span [ngClass]="{'strike': !delivery.wednesday}">Wo</span>, <span [ngClass]="{'strike': !delivery.thursday}">Do</span>, <span [ngClass]="{'strike': !delivery.friday}">Vr</span>, <span [ngClass]="{'strike': !delivery.saturday}">Za</span>, <span [ngClass]="{'strike': !delivery.sunday}">Zo</span></p>
              <p class="product-description text-one-line"><i></i></p>
              <ng-container><p class="option-price"><ng-container *ngIf="delivery.mode === 'D'">Levering vanaf</ng-container><ng-container *ngIf="delivery.mode === 'C'">Gratis levering vanaf</ng-container> {{delivery.minForFreeDelivery / 100 | currency: 'EUR'}}</p></ng-container>
            </div>

          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="full">
      <p class="button-close-order" (click)="close()">CLOSE</p>
    </div>

  </div>
</div>
