import {Component, Input, OnInit} from '@angular/core';
import {AbstractDetailComponent} from "../AbstractDetail.component";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {AbstractMenuComponent} from "../../menu/abstract.menu.component";

@Component({
  selector: 'app-order-detail-mobile',
  templateUrl: './order-detail-mobile.component.html',
  styleUrls: ['./order-detail-mobile.component.css']
})
export class OrderDetailMobileComponent extends AbstractDetailComponent {
  @Input() menuComponent: AbstractMenuComponent;

  constructor(http: HttpClient, router: Router) {
    super(http, router);
  }

  public init(): void {
    super.init();
  }

  protected getMenuComponent(): AbstractMenuComponent {
    return this.menuComponent;
  }

  public onDeliveryChanged(event): void {
    this.menuComponent.orderTicket.menuDeliveryId = event;
  }

}
