import {Product} from "./Product";
import {SupplementWrapper} from "./SupplementWrapper";
import {ProductCondimentWrapper} from "./ProductCondimentWrapper";
import {ProductExtraWrapper} from "./ProductExtraWrapper";
import {ProductSizeWrapper} from "./ProductSizeWrapper";
import {MenuVariantWrapper} from "./MenuVariantWrapper";

export class ProductOrderWrapper {
  public product: Product;

  public productVariantWrappers: MenuVariantWrapper[];
  public productSupplementWrappers: SupplementWrapper[];
  public productCondimentWrappers: ProductCondimentWrapper[];
  public productExtraWrappers: ProductExtraWrapper[];

  public orderCount: number;
  public note: string;

  public isPriceRequired(): boolean {
    if ( this.productVariantWrappers == null || this.productVariantWrappers.length < 1 ) {
      return true;
    }

    for ( const variantWrapper of this.productVariantWrappers ) {
      if ( variantWrapper != null && variantWrapper.productVariant != null && variantWrapper.productVariant.variant != null && variantWrapper.productVariant.variant.priceMode == 'S' ) {
        return false;
      }
    }

    return true;
  }
}
