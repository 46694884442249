import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from "@angular/forms";
import { HeaderComponent } from './order/header/header.component';
import { StatusComponent } from './proorder/status/status.component';
import { ShopReservedNameComponent } from './preorder/shop-reserved-name/shop-reserved-name.component';
import { ShopIdComponent } from './preorder/shop-id/shop-id.component';
import { TableIdComponent } from './preorder/table-id/table-id.component';
import { LoaderComponent } from './preorder/loader/loader.component';
import { ErrorDialogComponent } from './order/error-dialog/error-dialog.component';
import { MenuComponentHolderComponent } from "./order/menu/menu.component.holder.component";
import { MobileMenuComponent } from "./order/menu/menu-mobile/mobile.menu.component";
import { DesktopMenuComponent } from "./order/menu/menu-desktop/desktop.menu.component";
import { ProductDetailsDesktopComponent } from './order/product-details/product-details-desktop/product-details-desktop.component';
import { ProductDetailsMobileComponent } from './order/product-details/product-details-mobile/product-details-mobile.component';
import { SelectSupplementDesktopComponent } from "./order/select-supplement/select-supplement-desktop/select-supplement-desktop.component"
import { SelectSupplementMobileComponent } from "./order/select-supplement/select-supplement-mobile/select-supplement-mobile.component";
import { OrderDetailMobileComponent } from './order/detail/order-detail-mobile/order-detail-mobile.component';
import { OrderDetailDesktopComponent } from './order/detail/order-detail-desktop/order-detail-desktop.component';
import { DeliverySelectMobileComponent } from './order/detail/delivery-select-mobile/delivery-select-mobile.component';
import { DeliverySelectDesktopComponent } from './order/detail/delivery-select-desktop/delivery-select-desktop.component';
import { ShopDetailsMobileComponent } from './order/shop-details/shop-details-mobile/shop-details-mobile.component';
import { ShopDetailsDesktopComponent } from './order/shop-details/shop-details-desktop/shop-details-desktop.component';
import { MyHttpHandlerComponent } from './handlers/http/my-http-handler/my-http-handler.component';
import { TutorialComponent } from './order/tutorial/tutorial.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    StatusComponent,
    ShopReservedNameComponent,
    ShopIdComponent,
    TableIdComponent,
    LoaderComponent,
    ErrorDialogComponent,

    // Order
    MenuComponentHolderComponent,
    MobileMenuComponent,
    DesktopMenuComponent,
    ProductDetailsDesktopComponent,
    ProductDetailsMobileComponent,
    SelectSupplementDesktopComponent,
    SelectSupplementMobileComponent,
    OrderDetailMobileComponent,
    OrderDetailDesktopComponent,
    ShopDetailsMobileComponent,
    ShopDetailsDesktopComponent,
    MyHttpHandlerComponent,
    TutorialComponent,
    DeliverySelectMobileComponent,
    DeliverySelectDesktopComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
