import {Component, Input, OnInit} from '@angular/core';
import {AbstractShopDetailsComponent} from "../abstract-shop-details-component";
import {AbstractMenuComponent} from "../../menu/abstract.menu.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-shop-details-desktop',
  templateUrl: './shop-details-desktop.component.html',
  styleUrls: ['./shop-details-desktop.component.css']
})
export class ShopDetailsDesktopComponent extends AbstractShopDetailsComponent implements OnInit {
  @Input() menuComponent: AbstractMenuComponent;

  constructor(router: Router) {
    super(router);
  }

  ngOnInit(): void {
    super.onInit();
  }

  protected getMenuComponent(): AbstractMenuComponent {
    return this.menuComponent;
  }

}
