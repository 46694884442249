import {ProductCondiment} from "./ProductCondiment";

export class ProductCondimentWrapper {
  public productCondiment: ProductCondiment;

  public value: boolean;

  public click(productCondimentWrappers: ProductCondimentWrapper[]): void {
    this.value = !this.value;

    if ( this.productCondiment.condiment.similarOptionUniqueMaker != null && this.productCondiment.condiment.similarOptionUniqueMaker != '' ) {
      for ( const productCondimentWrapper of productCondimentWrappers ) {
        if ( this.productCondiment.condiment.similarOptionUniqueMaker === productCondimentWrapper.productCondiment.condiment.similarOptionUniqueMaker ) {
          productCondimentWrapper.value = this.value;
        }
      }
    }
  }
}
