<div class="product-popup-background" [ngClass]="{'popup-open': isOpen}" (click)="close(true)"></div>
<div class="product-popup-close" [ngClass]="{'popup-open': isOpen}" (click)="close(true)">
  <div class="close">
    <p class="close-text">SLUIT</p><img class="close-icon" src="../../../assets/icons/delete_icon.png"/>
  </div>
</div>
<div class="product-popup-box" [ngClass]="{'popup-open': isOpen}">
  <div class="popup-body">
    <!-- Overzicht -->
    <h3 class="bestelmandje-title">Overzicht</h3>
    <ng-container *ngIf="orderTicket != null">
      <ng-container *ngFor="let item of orderTicket.orderItems">
        <div class="order-item-detail">

          <div class="order-item-detail-line">
            <div class="order-item-detail-left">
              <p><strong>{{item.orderCount}}x {{item.product.details[0].name}}</strong></p>
              <p><strong></strong></p>

              <div class="order-item-details">

                <ng-container *ngIf="item.variantSelections != null">
                  <ng-container *ngFor="let variantSelection of item.variantSelections">
                    <p>{{variantSelection.variant.details[0].name}}: {{variantSelection.option.details[0].name}}</p>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="item.supplementSelections != null">
                  <ng-container *ngFor="let supplementSelection of item.supplementSelections">
                    <p>{{supplementSelection.supplement.details[0].name}}: {{supplementSelection.product.details[0].name}}</p>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="item.condimentSelections != null && item.condimentSelections.length > 0">
                  <p>Condimenten</p>
                  <ng-container *ngFor="let condimentSelection of item.condimentSelections">
                    <p><ng-container *ngIf="condimentSelection.value">+</ng-container><ng-container *ngIf="!condimentSelection.value">-</ng-container> {{condimentSelection.condiment.details[0].name}}</p>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="item.extraSelections != null && item.extraSelections.length > 0">
                  <p>Extra's</p>
                  <ng-container *ngFor="let extraSelection of item.extraSelections">
                    <p>+ {{extraSelection.condiment.details[0].name}}</p>
                  </ng-container>
                </ng-container>

                <div>
                  <p>{{item.note}}</p>
                </div>

              </div>
            </div>

            <div class="order-item-detail-right">
              <div class="product-count">
                <div class="round-button" (click)="orderItemMin(item)"><img src="../../../assets/icons/min.png"/></div><div class="product-count-number"><b>{{item.orderCount}}</b></div><div class="round-button" (click)="orderItemPlus(item)"><img src="../../../assets/icons/plus.png"/></div>
              </div>
            </div>
          </div>

          <div class="order-item-detail-line">
            <div class="order-item-detail-right">
              <div>
                <p class="order-ticket-sum-right"><strong>{{(item.price * item.orderCount) / 100 | currency: 'EUR'}}</strong></p>
              </div>
            </div>
          </div>

        </div>
      </ng-container>

      <ng-container *ngIf="orderTicket.destination == 'D'">
        <div class="order-item-detail-no-border">
          <p class="order-ticket-sum-left"><strong>Lever kosten</strong></p><p class="order-ticket-sum-right"><strong><ng-container *ngIf="orderTicket.menuDeliveryId == null">Kies een gemeente</ng-container><ng-container *ngIf="orderTicket.menuDeliveryId != null"><ng-container *ngIf="!isFree()">{{getDeliveryCost() / 100 | currency: 'EUR'}}</ng-container><ng-container *ngIf="isFree()">Gratis</ng-container></ng-container></strong></p>
        </div>
      </ng-container>

      <div class="order-item-detail-no-border">
        <p class="order-ticket-sum-left"><strong>Totaal</strong></p><p class="order-ticket-sum-right"><strong><ng-container *ngIf="isFree()">{{(orderTicket.price / 100) | currency: 'EUR'}}</ng-container><ng-container *ngIf="!isFree()">{{(orderTicket.price + getDeliveryCost()) / 100 | currency: 'EUR'}}</ng-container></strong></p>
      </div>

      <div class="order-item-detail-no-border">
        <p><strong>Notitie</strong></p>
        <textarea [(ngModel)]="orderTicket.note" class="product-note" rows="5"></textarea>
      </div>
    </ng-container>

    <!-- Klant gegevens -->
    <h3 class="bestelmandje-title">Klant gegevens</h3>
    <ng-container *ngIf="orderTicket != null">
      <div class="order-item-detail-no-border">
        <p>Naam*</p>
        <label><input type="text" class="full-input" [(ngModel)]="orderTicket.name"/></label>
      </div>

      <div class="order-item-detail-no-border">
        <p>Tel./Gsm<ng-container *ngIf="orderTicket.destination != 'T'">*</ng-container></p>
        <label><input type="tel" class="full-input" [(ngModel)]="orderTicket.phone"/></label>
      </div>

      <div class="order-item-detail-no-border">
        <p>Email<ng-container *ngIf="orderTicket.destination != 'T'"></ng-container></p>
        <label><input type="email" class="full-input" [(ngModel)]="orderTicket.email"/></label>
      </div>
    </ng-container>

    <!-- Levering -->
    <h3 class="bestelmandje-title">Levering</h3>
    <ng-container *ngIf="orderTicket != null">
      <div class="order-item-detail-no-border">
        <div class="order-item-detail-no-border" *ngIf="orderTicket.destination == 'T'">
          <label><input name="destination" type="radio" [(ngModel)]="orderTicket.destination" value="T" [disabled]="orderTicket.destination != 'T'"/> Aan tafel</label>
        </div>
        <div class="order-item-detail-no-border" *ngIf="orderTicket.destination != 'T'">
          <label><input name="destination" type="radio" [(ngModel)]="orderTicket.destination" value="P" [disabled]="orderTicket.destination == 'T'"/> Afhalen</label>
        </div>
        <div class="order-item-detail-no-border" *ngIf="orderTicket.destination != 'T'">
          <label><input name="destination" type="radio" [(ngModel)]="orderTicket.destination" value="D" [disabled]="orderTicket.destination == 'T'"/> Laten leveren</label>
        </div>
      </div>

      <ng-container *ngIf="orderTicket.destination != 'T'">
        <div class="order-item-detail-no-border">
          <p>Gemeente<ng-container *ngIf="orderTicket.destination == 'D'">*</ng-container></p>
          <ng-container *ngIf="orderTicket.menuDeliveryId === null">
            <div class="product-size-box" (click)="selectDeliveryComponent.open(orderTicket, menuDeliveries)">
              <p class="option-name">Selecteer hier een gemeente...</p>
            </div>
          </ng-container>
          <ng-container *ngIf="menuDeliveries != null">
            <ng-container *ngFor="let delivery of menuDeliveries">
              <ng-container *ngIf="orderTicket.menuDeliveryId !== null && orderTicket.menuDeliveryId === delivery.id">
                <div class="product-size-box" [ngClass]="{'supplement-selected': orderTicket.menuDeliveryId === delivery.id}" (click)="selectDeliveryComponent.open(orderTicket, menuDeliveries)">
                  <div class="product-details">
                    <h3 class="product-name text-one-line"><ng-container *ngIf="delivery.details != null && delivery.details.length > 0">{{delivery.details[0].name}}</ng-container></h3>
                    <p><span [ngClass]="{'strike': !delivery.monday}">Ma</span>, <span [ngClass]="{'strike': !delivery.tuesday}">Di</span>, <span [ngClass]="{'strike': !delivery.wednesday}">Wo</span>, <span [ngClass]="{'strike': !delivery.thursday}">Do</span>, <span [ngClass]="{'strike': !delivery.friday}">Vr</span>, <span [ngClass]="{'strike': !delivery.saturday}">Za</span>, <span [ngClass]="{'strike': !delivery.sunday}">Zo</span></p>
                    <p class="product-description text-one-line"><i></i></p>
                    <ng-container><p class="option-price"><ng-container *ngIf="delivery.mode === 'D'">Levering vanaf</ng-container><ng-container *ngIf="delivery.mode === 'C'">Gratis levering vanaf</ng-container> {{delivery.minForFreeDelivery / 100 | currency: 'EUR'}}</p></ng-container>
                  </div>

                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div class="order-item-detail-no-border">
          <p>Straat + nr<ng-container *ngIf="orderTicket.destination == 'D'">*</ng-container></p>
          <label><input type="text" class="full-input" [disabled]="orderTicket.destination != 'D'" [(ngModel)]="orderTicket.streetAndNr"/></label>
        </div>
      </ng-container>
    </ng-container>

    <!-- Levermoment -->
    <h3 class="bestelmandje-title">Levermoment</h3>
    <ng-container *ngIf="orderTicket != null">
      <div class="order-item-detail-no-border" *ngIf="orderTicket.destination != 'T'">
        <div class="order-item-detail-no-border">
          <label><input name="requested-time" type="radio" [(ngModel)]="specifiedDeliverTime" [value]="false"/> Zo snel mogelijk</label>
        </div>

        <div class="order-item-detail-no-border">
          <label><input name="requested-time" type="radio" [(ngModel)]="specifiedDeliverTime" [value]="true"/> Gewenste levertijd</label>
        </div>

        <div class="order-item-detail-no-border">
          <input [disabled]="specifiedDeliverTime == false" type="time" [(ngModel)]="deliverTime"/>
        </div>
      </div>
    </ng-container>

    <!-- Betaling -->
    <h3 class="bestelmandje-title">Betaling</h3>
    <ng-container *ngIf="orderTicket != null">
      <div class="order-item-detail-no-border">
        <div class="order-item-detail-no-border">
          <label><input name="payment" type="radio" [(ngModel)]="payAfter" [value]="true"/> Contant betalen</label>
        </div>
        <div class="order-item-detail-no-border">
          <label><input name="payment" type="radio" [(ngModel)]="payAfter" [value]="false" [disabled]="menu == null || menu.allowOnlinePayments !== 'Y'"/> Nu online betalen</label>
        </div>
        <div *ngIf="menu != null && menu.allowOnlinePayments === 'Y'" class="order-item-detail">
          <p><i>Bancontact, KBC CBC, Belfius, PayPal, Apple pay, Visa, MasterCard, Maestro</i></p>
        </div>
        <div *ngIf="menu == null || menu.allowOnlinePayments !== 'Y'" class="order-item-detail-no-border">
          <p><i>Online betalen is momenteel niet actief voor deze winkel/zaak</i></p>
        </div>
      </div>
    </ng-container>

    <div class="full">
      <div class="product-button-group">
        <button class="button-place-order" (click)="submit()">Bestellen</button>
      </div>
      <p class="button-close-order" (click)="close(true)">CLOSE</p>
    </div>
  </div>
</div>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
<app-delivery-select-desktop [detailComponent]="this"></app-delivery-select-desktop>
