import {Component, Input, OnInit} from '@angular/core';
import {AbstractProductDetailsComponent} from "../abstract-product-details.component";
import {HttpClient} from "@angular/common/http";
import {AbstractMenuComponent} from "../../menu/abstract.menu.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-product-details-desktop',
  templateUrl: './product-details-desktop.component.html',
  styleUrls: ['./product-details-desktop.component.css']
})
export class ProductDetailsDesktopComponent extends AbstractProductDetailsComponent {
  @Input() menuComponent: AbstractMenuComponent;

  constructor(http: HttpClient, router: Router) {
    super(http, router);
  }

  public init(): void {
    super.init();
  }

  protected getMenuComponent(): AbstractMenuComponent {
    return this.menuComponent;
  }
}
