import {AbstractMenuComponent} from "../menu/abstract.menu.component";
import {Router} from "@angular/router";

export abstract class AbstractShopDetailsComponent {
  private orderTicketId: string;
  private qrClientToken: string;

  public isOpen = false;
  public currentDay = new Date().getDay();

  protected constructor(private router: Router) {
  }

  protected onInit(): void {
    this.getMenuComponent().setShopDetailsComponent(this);
  }

  protected abstract getMenuComponent(): AbstractMenuComponent;

  public open(orderTicketId: string, qrClientToken: string): void {
    this.orderTicketId = orderTicketId;
    this.qrClientToken = qrClientToken;

    this.isOpen = true;
  }

  public close(reallyClose: boolean): void {
    if ( reallyClose && !this.router.navigateByUrl(this.orderTicketId + '/' + this.qrClientToken + '/na') ) {
      alert('Failed to open order list');
    }
    else {
      this.isOpen = false;
    }
  }
}
