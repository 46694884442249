import {AbstractMenuComponent} from "../abstract.menu.component";
import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-order-menu-mobile',
  templateUrl: './mobile.menu.component.html',
  styleUrls: ['./mobile.menu.component.css']
})
export class MobileMenuComponent extends AbstractMenuComponent {

  constructor(route: ActivatedRoute, router: Router, http: HttpClient) {
    super(route, router, http);
  }

  public init(): void {
    super.init();
  }

  protected doDeviceSpecificStuff(): void {
    document.getElementById("slideContainer").style.setProperty("--n", this.categories.length.toString());
    // @ts-ignore
    slideContainer = document.getElementById("slideContainer");
    // @ts-ignore
    i = 0;

    // @ts-ignore
    setTimeout(() => headerCategorySelected2(), 200);
  }

  justClose(): void {
  }

  justOpen(): void {
  }


}
