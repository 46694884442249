import {Component, Input, OnInit} from '@angular/core';
import {AbstractDeliverySelectComponent} from "../AbstractDeliverySelectComponent";
import {MenuOrderTicket} from "../../../domain/MenuOrderTicket";
import {MenuDelivery} from "../../../domain/MenuDelivery";
import {AbstractDetailComponent} from "../AbstractDetail.component";

@Component({
  selector: 'app-delivery-select-mobile',
  templateUrl: './delivery-select-mobile.component.html',
  styleUrls: ['./delivery-select-mobile.component.css']
})
export class DeliverySelectMobileComponent extends AbstractDeliverySelectComponent implements OnInit {
  @Input() detailComponent: AbstractDetailComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.detailComponent.setSelectDeliveryComponent(this);
  }

}
