import {MenuOrderTicket} from "../../domain/MenuOrderTicket";
import {MenuDelivery} from "../../domain/MenuDelivery";

export abstract class AbstractDeliverySelectComponent {
  public isOpen = false;

  public orderTicket: MenuOrderTicket;
  public menuDeliveries: MenuDelivery[];

  public open(orderTicket: MenuOrderTicket, menuDeliveries: MenuDelivery[]): void {
    this.orderTicket = orderTicket;
    this.menuDeliveries = menuDeliveries;

    this.isOpen = true;
  }

  public close(): void {
    this.orderTicket = null;
    this.menuDeliveries = null;

    this.isOpen = false;
  }

  public selectDelivery(menuDelivery: MenuDelivery) {
    this.orderTicket.menuDeliveryId = menuDelivery.id;
    setTimeout(() => {
      this.close();
    }, 250);
  }
}
