import {Component, Input, OnInit} from '@angular/core';
import {AbstractProductDetailsComponent} from "../abstract-product-details.component";
import {HttpClient} from "@angular/common/http";
import {AbstractMenuComponent} from "../../menu/abstract.menu.component";
import {SupplementProductWrapper} from "../../../domain/SupplementProductWrapper";
import {SupplementWrapper} from "../../../domain/SupplementWrapper";
import {Router} from "@angular/router";

@Component({
  selector: 'app-product-details-mobile',
  templateUrl: './product-details-mobile.component.html',
  styleUrls: ['./product-details-mobile.component.css']
})
export class ProductDetailsMobileComponent extends AbstractProductDetailsComponent {
  @Input() menuComponent: AbstractMenuComponent;

  constructor(http: HttpClient, router: Router) {
    super(http, router);
  }

  public init(): void {
    super.init();
  }

  protected getMenuComponent(): AbstractMenuComponent {
    return this.menuComponent;
  }

  public supplementProductClick(supplementWrapper: SupplementWrapper, supplementProductWrapper: SupplementProductWrapper): void {
    const oldValue = supplementWrapper.selectedProductId;

    if ( oldValue == supplementProductWrapper.supplementProduct.productId ) {
      const mode = supplementWrapper.productSupplement.supplement.mode;
      if ( mode !== 'R' && mode !== 'E' && mode !== 'Q' ) {
        supplementWrapper.selectedProductId = supplementProductWrapper.supplementProduct.productId;
      }
      else {
        supplementWrapper.selectedProductId = null;
      }
    }
    else {
      supplementWrapper.selectedProductId = supplementProductWrapper.supplementProduct.productId;
    }
  }
}
