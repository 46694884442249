import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Menu} from "./domain/menu";
import {Product} from "./domain/Product";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  ngOnInit() {
  }
}
