import { Component, OnInit } from '@angular/core';
import {AppConfigurator} from "../../domain/AppConfigurator";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Defaults} from "../../constants/defaults";
import {ClientRegistrationForm} from "../../domain/ClientRegistrationForm";
import {Config} from "../../handlers/Config";

@Component({
  selector: 'app-shop-id',
  templateUrl: './shop-id.component.html',
  styleUrls: ['./shop-id.component.css']
})
export class ShopIdComponent implements OnInit {

  public appConfigurator: AppConfigurator;
  public shopId: string;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) {
  }

  ngOnInit(): void {
    this.shopId = this.route.snapshot.paramMap.get('shopId');

    this.getConfigurator();
  }

  getConfigurator(): void {
    const headers = new HttpHeaders({
      applicationId: Defaults.applicationId,
      applicationToken: Defaults.applicationToken
    });

    this.http.get<ClientRegistrationForm>(Defaults.SERVER_API + 'qr/configuration/fromShop/' + this.shopId, {headers}).subscribe(
      (result: ClientRegistrationForm) => {
        Config.setRegistrationId(result.id);
        Config.setRegistrationToken(result.token);
        this.router.navigateByUrl('menu/selectedMenu');
      }, (error) => {

      }, () => {

      }
    );
  }
}
