<app-header [menuComponent]="this" [shop]="shop" [table]="table" [orderItemCount]="orderTicket != null? orderTicket.orderItems.length: 0" [useBasket]="true"></app-header>

<div class="container">
  <div class="sub-container">
    <ng-container *ngIf="categories != null">
      <ng-container *ngFor="let category of categories">
        <div class="category-container">
          <h3 class="category-title"><ng-container *ngIf="category.details != null && category.details.length > 0 && category.details[0] != null">{{category.details[0].name}}</ng-container></h3>
          <div>
            <ng-container *ngIf="category.products != null">
              <ng-container *ngFor="let product of category.products">
                <ng-container *ngIf="product != null && product.active === 'A'">
                  <div class="product" (click)="openProductDetail(product)">
                    <ng-container *ngIf="category.showProductImagesQR != '0'">
                      <div class="product-image product-image-{{category.showProductImagesQR}}" style="background-image: url('{{CONTENT_SERVER}}{{category.showProductImagesQR}}/128/{{product.productImageId}}.png')">
                      </div>
                    </ng-container>

                    <div class="product-details">
                      <div>
                        <p class="product-name"><ng-container *ngIf="product.details != null && product.details.length > 0 && product.details[0] != null">{{product.details[0].name}}</ng-container></p>
                      </div>
                      <div *ngIf="category.columnCountQR == 1">
                        <i class="product-description"><ng-container *ngIf="product.details != null && product.details.length > 0 && product.details[0] != null">{{product.details[0].description}}</ng-container></i>
                      </div>
                      <div>
                        <b class="product-price">{{product.price / 100 | currency:'EUR'}}</b>
                      </div>
                    </div>
                    <div class="product-line product-line-3">

                    </div>

                    <ng-container *ngIf="product.orderCount > 0">
                      <div class="product-count-dot">
                        {{product.orderCount}}
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>

        </div>
      </ng-container>
    </ng-container>
  </div>

  <div><!-- TODO plaats in component -->
    <div class="footer-logo" (click)="openShopDetail()">
      <h3>BESTELMANDJE.BE</h3>
      <p><i>Tik hier voor info</i></p>
    </div>
  </div>

</div>
<app-product-details-desktop [menuComponent]="this"></app-product-details-desktop>
<app-order-detail-desktop [menuComponent]="this"></app-order-detail-desktop>
<app-shop-details-desktop [menuComponent]="this"></app-shop-details-desktop>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
