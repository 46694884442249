import { Component, OnInit } from '@angular/core';
import {AppConfigurator} from "../../domain/AppConfigurator";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Defaults} from "../../constants/defaults";
import {Config} from "../../handlers/Config";
import {ClientRegistrationForm} from "../../domain/ClientRegistrationForm";
import {QrMenuOrderTicketCreateObject} from "../../domain/QrMenuOrderTicketCreateObject";
import {IHaveMyHttpHandler} from "../../handlers/http/my-http-handler/IHaveMyHttpHandler";

@Component({
  selector: 'app-table-id',
  templateUrl: './table-id.component.html',
  styleUrls: ['./table-id.component.css']
})
export class TableIdComponent extends IHaveMyHttpHandler {

  public appConfigurator: AppConfigurator;
  public tableId: string;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) {
    super();
  }

  public init(): void {
    this.tableId = this.route.snapshot.paramMap.get('tableId');

    this.getConfigurator();
  }

  getConfigurator(): void {
    const headers = new HttpHeaders({
      applicationId: Defaults.applicationId,
      applicationToken: Defaults.applicationToken
    });

    this.myHttpHandlerComponent.get<QrMenuOrderTicketCreateObject>(this.http.get<QrMenuOrderTicketCreateObject>(Defaults.SERVER_API + 'qr/configuration/fromTable/' + this.tableId, {headers}), (result: QrMenuOrderTicketCreateObject) => {
      if ( result.menuOrderTicket.languageCode != null && result.menuOrderTicket.menuId != null ) {
        if ( !this.router.navigateByUrl(result.menuOrderTicket.id + '/' + result.menuOrderTicket.qrClientToken + '/tutorial') ) {
          alert("Navigation error");
        }
      }
    });
  }

  justClose(): void {
  }

  justOpen(): void {
  }

  public isReady(): boolean {
    return true;
  }
}
