<div class="loader-background" *ngIf="isOpen"></div>
<div class="loader" *ngIf="isOpen">
  <div class="loader-1"></div>
  <div class="loader-2"></div>
</div>

<div class="product-popup-background" [ngClass]="{'popup-open': errorIsOpen}"></div>
<div class="product-popup-box" [ngClass]="{'popup-open': errorIsOpen}">
  <div>
    <p><b>{{errorMessage}}</b></p>
  </div>
  <div>
    <button (click)="close()">OK</button>
  </div>
</div>
