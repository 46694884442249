<div class="explanation">
  <div class="explanation-box">
    <div class="explanation-item white-border-bottom">
      <div class="explanation-item-left">
        <img src="assets/icons/streamline-icon-shopping-basket-1@140x140.svg">
      </div>
      <div class="explanation-item-right">
        <p>Bestellijst beheren en bestelling plaatsen via de mandje icon.</p>
      </div>
    </div>

    <div class="explanation-item white-border-bottom">
      <div class="explanation-item-left">
        <img src="assets/icons/streamline-icon-gesture-swipe-horizontal-left-2@140x140.svg">
      </div>
      <div class="explanation-item-right">
        <p>Swipe naar links om naar volgende categorie te gaan.</p>
      </div>
    </div>

    <div class="explanation-item white-border-bottom">
      <div class="explanation-item-left">
        <img src="assets/icons/streamline-icon-gesture-swipe-horizontal-right-2@140x140.svg">
      </div>
      <div class="explanation-item-right">
        <p>Swipe naar rechts om naar vorige categorie te gaan.</p>
      </div>
    </div>

    <div class="explanation-item">
      <div class="explanation-item-left">
        <img src="assets/icons/streamline-icon-touch-finger@140x140.svg">
      </div>
      <div class="explanation-item-right">
        <p>Informatie over de horeca zaak: tik op de logo van de zaak.</p>
      </div>
    </div>

    <a class="open-menu-button" (click)="openUrl()">OPEN MENU</a>
  </div>
</div>
