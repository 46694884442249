import {Menu} from "../domain/menu";
import {Category} from "../domain/category";
import {MenuOrderTicket} from "../domain/MenuOrderTicket";

export class MenuHandler {


  public static linkRelations(menu: Menu): Category[] {
    const categories = Array();

    for (const category of menu.categories) {
      if ( category.active === 'A' && category.inMenu === 'E' ) {
        categories.push(category);
      }

      for (const product of category.products) {
        for ( const productVariant of product.productVariants ) {
          for ( const variant of menu.variants ) {
            if ( productVariant.variantId === variant.id ) {
              productVariant.variant = variant;
            }
          }
        }

        for (const productSupplement of product.productSupplements) {
          for (const supplement of menu.supplements) {
            if (productSupplement.supplementId === supplement.id) {
              productSupplement.supplement = supplement;
            }
          }
        }

        for (const supplement of menu.supplements) {
          for (const supplementProduct of supplement.supplementProducts) {
            if (supplementProduct.productId === product.id) {
              supplementProduct.product = product;
            }
          }
        }

        for (const condiment of menu.condiments) {
          for (const productCondiment of product.productCondiments) {
            if (productCondiment.condimentId === condiment.id) {
              productCondiment.condiment = condiment;
            }
          }

          for (const productExtra of product.productExtras) {
            if (productExtra.condimentId === condiment.id) {
              productExtra.condiment = condiment;
            }
          }
        }
      }
    }

    return categories;
  }

  public static linkOrders(menu: Menu, orderTicket: MenuOrderTicket): void {
    if ( menu == null || orderTicket == null ) {
      alert('Fatal error');
      return;
    }

    if ( menu.categories != null ) {
      for ( const category of menu.categories ) {
        if ( category != null && category.products != null ) {
          for ( const product of category.products ) {
            if ( product != null ) {
              product.orderCount = 0;

              if ( orderTicket.orderItems != null ) {
                for ( const orderItem of orderTicket.orderItems ) {
                  if ( orderItem != null ) {
                    if ( orderItem.productId === product.id ) {
                      product.orderCount += orderItem.orderCount;
                      orderItem.product = product;
                    }

                    if ( orderItem.supplementSelections != null ) {
                      for ( const supplementSelection of orderItem.supplementSelections ) {
                        if ( supplementSelection != null ) {
                          if ( product.id === supplementSelection.productId ) {
                            supplementSelection.product = product;
                          }
                        }
                      }
                    }
                  }
                }
              }

            }
          }
        }
      }
    }

    // TODO doe verder met is not nulls

    for ( const orderItem of orderTicket.orderItems ) {
      for ( const variantSelection of orderItem.variantSelections ) {

        for ( const variant of menu.variants ) {
          if ( variant.id === variantSelection.variantId ) {
            variantSelection.variant = variant;
          }

          for ( const option of variant.variantOptions ) {
            if ( option.id === variantSelection.optionId ) {
              variantSelection.option = option;
            }
          }
        }
      }

      for ( const supplementSelection of orderItem.supplementSelections ) {
        for (const supplement of menu.supplements ) {
          if ( supplement.id === supplementSelection.supplementId ) {
            supplementSelection.supplement = supplement;
          }
        }
      }

      for ( const condiment of menu.condiments ) {
        for ( const condimentSelection of orderItem.condimentSelections ) {
          if ( condimentSelection.condimentId === condiment.id ) {
            condimentSelection.condiment = condiment;
          }
        }

        for ( const extraSelection of orderItem.extraSelections ) {
          if ( extraSelection.condimentId === condiment.id ) {
            extraSelection.condiment = condiment;
          }
        }
      }
    }
  }
}
