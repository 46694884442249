import {MenuVariant} from "./MenuVariant";
import {MenuVariantOptionWrapper} from "./MenuVariantOptionWrapper";
import {MenuProductVariant} from "./MenuProductVariant";

export class MenuVariantWrapper {
  public productVariant: MenuProductVariant;
  public optionWrappers: MenuVariantOptionWrapper[];

  public optionClicked(theOptionWrapper: MenuVariantOptionWrapper): void {
    for ( const optionWrapper of this.optionWrappers ) {
      optionWrapper.selected = false;
    }

    theOptionWrapper.selected = true;

    if ( theOptionWrapper.variantOption.similarOptionUniqueMaker != null && theOptionWrapper.variantOption.similarOptionUniqueMaker != '' ) {
      for ( const optionWrapper of this.optionWrappers ) {
        if ( optionWrapper.variantOption.similarOptionUniqueMaker === theOptionWrapper.variantOption.similarOptionUniqueMaker ) {
          optionWrapper.selected = true;
        }
      }
    }
  }
}
