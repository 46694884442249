import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {IHaveMyHttpHandler} from "./IHaveMyHttpHandler";

@Component({
  selector: 'app-my-http-handler',
  templateUrl: './my-http-handler.component.html',
  styleUrls: ['./my-http-handler.component.css']
})
export class MyHttpHandlerComponent implements OnInit {
  @Input() iHaveMyHttpHandler: IHaveMyHttpHandler;

  public isOpen = false;

  public errorMessage: string;
  public errorIsOpen = false;

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit(): void {
    this.iHaveMyHttpHandler.setMyHttpHandlerComponent(this);
  }

  public get<T>(observable: Observable<T>, next: (value: T) => void): void {
    this.isOpen = true;

    observable.subscribe((result: T) => {
      next(result);
    }, error => {
      if ( error.code == 401 ) {
        this.router.navigateByUrl("test"); //
      }
      else {
        if ( error != null && error.error != null && error.error.message != null ) {
          this.showError('server', error.error.message, []);
        }
        else {
          this.showError('server', 'Failed to read error message', []);
        }
      }

      this.isOpen = false;
    }, () => {
      this.isOpen = false;
    });
  }

  public showError(source: string, errorMessage: string, errorDetails: string[]): void {
    this.errorMessage = errorMessage;
    this.errorIsOpen = true;

    this.iHaveMyHttpHandler.justClose();
  }

  public close(): void {
    this.errorMessage = null;
    this.errorIsOpen = false;

    this.iHaveMyHttpHandler.justOpen();
  }

}
