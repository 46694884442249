export class Config {
  private static ID = "app_registration_id_3169";
  private static TOKEN = "app_registration_token_3169";

  private static SHOP_NAME = "SHOP_NAME";

  public static setRegistrationId(id: string): string {
    localStorage.setItem(this.ID, id);
    return this.getRegistrationId();
  }
  public static setRegistrationToken(token: string): string {
    localStorage.setItem(this.TOKEN, token);
    return this.getRegistrationToken();
  }

  public static getRegistrationId(): string {
    return localStorage.getItem(this.ID);
  }

  public static getRegistrationToken(): string {
    return localStorage.getItem(this.TOKEN);
  }

  public static setShopName(shopName: string): string {
    localStorage.setItem(this.SHOP_NAME, shopName);
    return this.getShopName();
  }

  public static getShopName(): string {
    return localStorage.getItem(this.SHOP_NAME);
  }
}
