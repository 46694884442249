import {Component, Input, OnInit} from '@angular/core';
import {AbstractDeliverySelectComponent} from "../AbstractDeliverySelectComponent";
import {AbstractDetailComponent} from "../AbstractDetail.component";

@Component({
  selector: 'app-delivery-select-desktop',
  templateUrl: './delivery-select-desktop.component.html',
  styleUrls: ['./delivery-select-desktop.component.css']
})
export class DeliverySelectDesktopComponent extends AbstractDeliverySelectComponent implements OnInit {
  @Input() detailComponent: AbstractDetailComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.detailComponent.setSelectDeliveryComponent(this);
  }

}
