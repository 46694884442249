<div class="product-popup-background" [ngClass]="{'popup-open': isOpen}" (click)="close(true)"></div>
<div class="product-popup-close" [ngClass]="{'popup-open': isOpen}" (click)="close(true)">
  <div class="close">
    <p class="close-text">SLUIT</p><img class="close-icon" src="../../../../assets/icons/delete_icon.png"/>
  </div>
</div>
<div class="product-popup-box" [ngClass]="{'popup-open': isOpen}">
  <ng-container *ngIf="menuComponent.shop != null">

    <div class="full">
      <h3 class="bestelmandje-title">{{menuComponent.shop.name}}</h3>
      <h4>Openingsuren</h4>
      <table>
        <tr [ngClass]="{'current-day': currentDay == 1}">
          <td>Maandag</td>
          <td class="closures-hours"><ng-container *ngIf="menuComponent.shop.menuClosureOpenOnMonday">{{menuComponent.shop.menuClosureMonday.replace('-', ' - ')}}</ng-container><ng-container *ngIf="!menuComponent.shop.menuClosureOpenOnMonday">Gesloten</ng-container></td>
        </tr>
        <tr [ngClass]="{'current-day': currentDay == 2}">
          <td>Dinsdag</td>
          <td class="closures-hours"><ng-container *ngIf="menuComponent.shop.menuClosureOpenOnTuesday">{{menuComponent.shop.menuClosureTuesday.replace('-', ' - ')}}</ng-container><ng-container *ngIf="!menuComponent.shop.menuClosureOpenOnTuesday">Gesloten</ng-container></td>
        </tr>
        <tr [ngClass]="{'current-day': currentDay == 3}">
          <td>Woensdag</td>
          <td class="closures-hours"><ng-container *ngIf="menuComponent.shop.menuClosureOpenOnWednesday">{{menuComponent.shop.menuClosureWednesday.replace('-', ' - ')}}</ng-container><ng-container *ngIf="!menuComponent.shop.menuClosureOpenOnWednesday">Gesloten</ng-container></td>
        </tr>
        <tr [ngClass]="{'current-day': currentDay == 4}">
          <td>Donderdag</td>
          <td class="closures-hours"><ng-container *ngIf="menuComponent.shop.menuClosureOpenOnThursday">{{menuComponent.shop.menuClosureThursday.replace('-', ' - ')}}</ng-container><ng-container *ngIf="!menuComponent.shop.menuClosureOpenOnThursday">Gesloten</ng-container></td>
        </tr>
        <tr [ngClass]="{'current-day': currentDay == 5}">
          <td>Vrijdag</td>
          <td class="closures-hours"><ng-container *ngIf="menuComponent.shop.menuClosureOpenOnFriday">{{menuComponent.shop.menuClosureFriday.replace('-', ' - ')}}</ng-container><ng-container *ngIf="!menuComponent.shop.menuClosureOpenOnFriday">Gesloten</ng-container></td>
        </tr>
        <tr [ngClass]="{'current-day': currentDay == 6}">
          <td>Zaterdag</td>
          <td class="closures-hours"><ng-container *ngIf="menuComponent.shop.menuClosureOpenOnSaturday">{{menuComponent.shop.menuClosureSaturday.replace('-', ' - ')}}</ng-container><ng-container *ngIf="!menuComponent.shop.menuClosureOpenOnSaturday">Gesloten</ng-container></td>
        </tr>
        <tr [ngClass]="{'current-day': currentDay == 0}">
          <td>Zondag</td>
          <td class="closures-hours"><ng-container *ngIf="menuComponent.shop.menuClosureOpenOnSunday">{{menuComponent.shop.menuClosureSunday.replace('-', ' - ')}}</ng-container><ng-container *ngIf="!menuComponent.shop.menuClosureOpenOnSunday">Gesloten</ng-container></td>
        </tr>
      </table>
    </div>

    <div class="full">
      <h4>Adres</h4>
      <p>{{menuComponent.shop.address}}</p>
      <p>{{menuComponent.shop.zipcode}}, {{menuComponent.shop.town}}</p>
    </div>

    <div class="full">
      <h4>Telefoon</h4>
      <p>{{menuComponent.shop.phone}}</p>
    </div>

    <div class="full">
      <h4>Email</h4>
      <p>{{menuComponent.shop.email}}</p>
    </div>

    <div class="full">
      <h4>BTW nummer</h4>
      <p>{{menuComponent.shop.vatNr}}</p>
    </div>

    <h3 class="bestelmandje-title">Bestelmandje</h3>

    <div class="full">
      <h4>BTW nummer</h4>
      <p>BE0772957366</p>
    </div>

    <div class="full">
      <h4>Email</h4>
      <p>info@bestelmandje.be</p>
      <p>support@bestelmandje.be</p>
    </div>

    <div class="full">
      <p><a style="width: 100%; text-align: center;" href="https://www.bestelmandje.be/docs/AlgemeneVoorwaardenKlant.pdf" target="_blank">Algemene voorwaarden</a></p>
      <p><a style="width: 100%; text-align: center;" href="https://www.bestelmandje.be/docs/PrivacyStatementKlanten.pdf" target="_blank">Privacy statement</a></p>
    </div>

  </ng-container>
</div>
