import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ApplicationStateService {

  private readonly isMobile: boolean;

  constructor() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    this.isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  public getIsMobile() {
    return this.isMobile;
  }
}
