<div class="product-popup-box" [ngClass]="{'popup-open': isOpen, 'popup-shifted': selectSupplementComponent != null && selectSupplementComponent.isOpen}">
  <div class="popup-header">
    <div class="popup-header-back-container">
      <img (click)="close(true)" src="../../../assets/icons/arrow_back_black_24dp.svg">
    </div>
  </div>

  <div class="popup-body">
    <ng-container *ngIf="productOrderWrapper != null && productOrderWrapper.product != null">
      <div class="product-detail-image" *ngIf="productOrderWrapper.product.productImageId != null">
        <div class="product-image product-image-4" style="background-image: url('{{CONTENT_SERVER}}4/512/{{productOrderWrapper.product.productImageId}}.png')">
        </div>
      </div>

      <div class="full">
        <h3>{{productOrderWrapper.product.details[0].name}}</h3>
        <p>{{productOrderWrapper.product.details[0].description}}</p>
        <p *ngIf="productOrderWrapper.isPriceRequired()" class="product-price">{{productOrderWrapper.product.price / 100 | currency: 'EUR'}}</p>
      </div>

      <ng-container *ngIf="productOrderWrapper.productVariantWrappers != null">
        <ng-container *ngFor="let variantWrapper of productOrderWrapper.productVariantWrappers">
          <ng-container *ngIf="isEnabled(variantWrapper.productVariant, productOrderWrapper)">
            <div class="full">
              <h4>{{variantWrapper.productVariant.variant.details[0].name}}</h4>
              <ng-container *ngIf="variantWrapper.optionWrappers != null">
                <ng-container *ngFor="let optionWrapper of variantWrapper.optionWrappers">
                  <ng-container *ngIf="isEnabled(optionWrapper.variantOption, productOrderWrapper)">
                    <div class="product-size-box" [ngClass]="{'product-size-box-selected': optionWrapper.selected}" (click)="variantWrapper.optionClicked(optionWrapper)">
                      <p class="option-name">{{optionWrapper.variantOption.details[0].name}}</p>
                      <ng-container *ngIf="variantWrapper.productVariant.variant.priceMode == 'S'"><p class="option-price">{{(productOrderWrapper.product.price + optionWrapper.variantOption.price) / 100 | currency: 'EUR'}}</p></ng-container>
                      <ng-container *ngIf="variantWrapper.productVariant.variant.priceMode == 'D' && optionWrapper.variantOption.price != 0"><p class="option-price">+ {{optionWrapper.variantOption.price / 100 | currency: 'EUR'}}</p></ng-container>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="productOrderWrapper.productSupplementWrappers != null">
        <ng-container *ngFor="let supplementWrapper of productOrderWrapper.productSupplementWrappers">
          <ng-container *ngIf="isEnabled(supplementWrapper.productSupplement, productOrderWrapper)">

            <div class="full">
              <h4>{{supplementWrapper.productSupplement.supplement.details[0].name}}<ng-container *ngIf="supplementWrapper.productSupplement.supplement.mode == 'B' || supplementWrapper.productSupplement.supplement.mode == 'D'"> *</ng-container></h4>

              <ng-container *ngIf="supplementWrapper.selectedProductId === null">
                <div class="product-size-box" (click)="selectSupplementComponent.open(supplementWrapper)">
                  <p class="option-name">Tik hier voor opties...</p>
                </div>
              </ng-container>

              <ng-container *ngIf="supplementWrapper.supplementProductWrappers != null">
                <ng-container *ngFor="let supplementProductWrapper of supplementWrapper.supplementProductWrappers">
                  <ng-container *ngIf="supplementWrapper.selectedProductId !== null && supplementWrapper.selectedProductId === supplementProductWrapper.supplementProduct.productId">
                    <div class="product-size-box" [ngClass]="{'supplement-selected': supplementWrapper.selectedProductId === supplementProductWrapper.supplementProduct.productId}" (click)="selectSupplementComponent.open(supplementWrapper)">
                      <div class="supplement-product-image" style="background-image: url('{{CONTENT_SERVER}}4/64/{{supplementProductWrapper.supplementProduct.product.productImageId}}.png')">
                      </div>
                      <div class="product-details">
                        <h3 class="product-name text-one-line"><ng-container *ngIf="supplementProductWrapper.supplementProduct.product.details != null && supplementProductWrapper.supplementProduct.product.details.length > 0">{{supplementProductWrapper.supplementProduct.product.details[0].name}}</ng-container></h3>
                        <p class="product-description text-one-line"><i>{{supplementProductWrapper.supplementProduct.product.details[0].description}}</i></p>
                        <ng-container *ngIf="supplementWrapper.productSupplement.supplement.mode === 'P' || supplementWrapper.productSupplement.supplement.mode === 'D'"><p class="option-price">+{{calcPrice(supplementWrapper.productSupplement.supplement, supplementProductWrapper.supplementProduct.product) / 100 | currency: 'EUR'}}</p></ng-container>
                        <ng-container *ngIf="supplementWrapper.productSupplement.supplement.mode === 'F'"><p class="option-price">Free</p></ng-container>
                      </div>

                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>

          </ng-container>
        </ng-container>
      </ng-container>

      <div class="full">
        <ng-container *ngIf="productOrderWrapper != null && productOrderWrapper.productCondimentWrappers != null && isAtLeastOneEnabledCondiment(productOrderWrapper.productCondimentWrappers, productOrderWrapper)">
          <h4>Condimenten</h4>

          <ng-container *ngFor="let productCondimentWrapper of productOrderWrapper.productCondimentWrappers">
            <div class="check-box-item" (click)="productCondimentWrapper.click(productOrderWrapper.productCondimentWrappers)" *ngIf="isEnabled(productCondimentWrapper.productCondiment, productOrderWrapper)">
              <svg class="check-box-icon" viewBox="0, 0, 24, 24">
                <ng-container *ngIf="!productCondimentWrapper.value"><path class="check-box-icon-path" d="M22,0H2A2,2,0,0,0,0,2V22a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0ZM21,22H3a1,1,0,0,1-1-1V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1V21A1,1,0,0,1,21,22Z"/></ng-container>
                <ng-container *ngIf="productCondimentWrapper.value"><path class="check-box-icon-path" d="M22.455,0H1.545A1.547,1.547,0,0,0,0,1.545V22.454A1.547,1.547,0,0,0,1.545,24h20.91A1.547,1.547,0,0,0,24,22.454V1.545A1.547,1.547,0,0,0,22.455,0Zm-3.65,7.844-7,9.5a1.013,1.013,0,0,1-1.43.187l-5-4a1,1,0,1,1,1.25-1.561l4.187,3.35L17.2,6.656a1,1,0,1,1,1.61,1.187Z"/></ng-container>
              </svg>
              <ng-container *ngIf="productCondimentWrapper != null && productCondimentWrapper.productCondiment != null && productCondimentWrapper.productCondiment.condiment != null && productCondimentWrapper.productCondiment.condiment.details != null"><p class="check-box-text">{{productCondimentWrapper.productCondiment.condiment.details[0].name}}</p></ng-container>
            </div>
          </ng-container>

        </ng-container>
      </div>

      <div class="full">
        <ng-container *ngIf="productOrderWrapper != null && productOrderWrapper.productExtraWrappers != null && isAtLeastOneEnabledExtra(productOrderWrapper.productExtraWrappers, productOrderWrapper)">
          <h4>Extra's</h4>

          <ng-container *ngFor="let productExtraWrapper of productOrderWrapper.productExtraWrappers">
            <div class="check-box-item" (click)="productExtraWrapper.click(productOrderWrapper.productExtraWrappers)" *ngIf="isEnabled(productExtraWrapper.productExtra, productOrderWrapper)">
              <svg class="check-box-icon" viewBox="0, 0, 24, 24">
                <ng-container *ngIf="!productExtraWrapper.value"><path class="check-box-icon-path" d="M22,0H2A2,2,0,0,0,0,2V22a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0ZM21,22H3a1,1,0,0,1-1-1V3A1,1,0,0,1,3,2H21a1,1,0,0,1,1,1V21A1,1,0,0,1,21,22Z"/></ng-container>
                <ng-container *ngIf="productExtraWrapper.value"><path class="check-box-icon-path" d="M22.455,0H1.545A1.547,1.547,0,0,0,0,1.545V22.454A1.547,1.547,0,0,0,1.545,24h20.91A1.547,1.547,0,0,0,24,22.454V1.545A1.547,1.547,0,0,0,22.455,0Zm-3.65,7.844-7,9.5a1.013,1.013,0,0,1-1.43.187l-5-4a1,1,0,1,1,1.25-1.561l4.187,3.35L17.2,6.656a1,1,0,1,1,1.61,1.187Z"/></ng-container>
              </svg>
              <ng-container *ngIf="productExtraWrapper != null && productExtraWrapper.productExtra != null && productExtraWrapper.productExtra.condiment != null && productExtraWrapper.productExtra.condiment.details != null"><p class="check-box-text option-name">{{productExtraWrapper.productExtra.condiment.details[0].name}}</p></ng-container>
              <ng-container *ngIf="productExtraWrapper.productExtra.condiment.price > 0"><p class="extra-price">+{{productExtraWrapper.productExtra.condiment.price / 100 | currency: 'EUR'}}</p></ng-container>
            </div>
          </ng-container>

        </ng-container>
      </div>

      <div class="full">
        <h4>Notitie</h4>
        <textarea [(ngModel)]="productOrderWrapper.note" class="product-note" rows="5"></textarea>
      </div>

      <div class="full">
        <div class="product-button-group">
          <button class="button-product-change-count min" (click)="min()">-</button>
          <button class="button-add-product" (click)="submit()">Voeg product<ng-container *ngIf="productOrderWrapper.orderCount > 1">en</ng-container> toe ({{productOrderWrapper.orderCount}})</button>
          <button class="button-product-change-count plus" (click)="plus()">+</button>
        </div>
        <p class="button-close-product" (click)="close(true)">CLOSE</p>
      </div>
    </ng-container>
  </div>
</div>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
<app-select-supplement-mobile [productDetailsComponent]="this"></app-select-supplement-mobile>
