import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StatusComponent} from "./proorder/status/status.component";
import { ShopIdComponent} from "./preorder/shop-id/shop-id.component";
import { ShopReservedNameComponent} from "./preorder/shop-reserved-name/shop-reserved-name.component";
import { TableIdComponent} from "./preorder/table-id/table-id.component";
import {MenuComponentHolderComponent} from "./order/menu/menu.component.holder.component";
import {TutorialComponent} from "./order/tutorial/tutorial.component";


const routes: Routes = [
  {path: '', component: ShopReservedNameComponent},

  { path: ':shopReservedName', component: ShopReservedNameComponent },
  { path: 'order/:shopId', component: ShopIdComponent },
  { path: 'table/:tableId', component: TableIdComponent },
  { path: 'qr/scan/:tableId', component: TableIdComponent }, // Dont use these urls ever again on a qrCode

  { path: ':orderTicketId/:qrClientToken/tutorial', component: TutorialComponent },
  { path: ':orderTicketId/:qrClientToken/:menuState', component: MenuComponentHolderComponent },

  { path: 'menu/selectedMenu', component: MenuComponentHolderComponent },
  { path: 'order/status/:orderTicketId/:qrClientToken', component: StatusComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
