import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Defaults} from "../../constants/defaults";
import {IHaveMyHttpHandler} from "../../handlers/http/my-http-handler/IHaveMyHttpHandler";
import {QrMenuOrderTicketCreateObject} from "../../domain/QrMenuOrderTicketCreateObject";

@Component({
  selector: 'app-shop-reserved-name',
  templateUrl: './shop-reserved-name.component.html',
  styleUrls: ['./shop-reserved-name.component.css']
})
export class ShopReservedNameComponent extends IHaveMyHttpHandler {

  public shopReservedName: string;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) {
    super();
  }

  public init(): void {
    this.shopReservedName = this.route.snapshot.paramMap.get('shopReservedName');

    this.getConfigurator();
  }

  getConfigurator(): void {
    const headers = new HttpHeaders({
      applicationId: Defaults.applicationId,
      applicationToken: Defaults.applicationToken
    });

    this.myHttpHandlerComponent.get<QrMenuOrderTicketCreateObject>(this.http.get<QrMenuOrderTicketCreateObject>(Defaults.SERVER_API + 'qr/configuration/fromReservedName/' + this.shopReservedName, {headers}), (result: QrMenuOrderTicketCreateObject) => {
      if ( result.menuOrderTicket.languageCode != null && result.menuOrderTicket.menuId != null ) {
        if ( !this.router.navigateByUrl(result.menuOrderTicket.id + '/' + result.menuOrderTicket.qrClientToken + '/tutorial') ) {
          alert("Navigation error");
        }
      }
    });
  }

  justClose(): void {
  }

  justOpen(): void {
  }

  public isReady(): boolean {
    return true;
  }
}
