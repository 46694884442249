import {IHaveMyHttpHandler} from "../../handlers/http/my-http-handler/IHaveMyHttpHandler";
import {AbstractProductDetailsComponent} from "../product-details/abstract-product-details.component";
import {SupplementWrapper} from "../../domain/SupplementWrapper";
import {Defaults} from "../../constants/defaults";
import {SupplementProductWrapper} from "../../domain/SupplementProductWrapper";

export abstract class AbstractSelectSupplementComponent extends IHaveMyHttpHandler {

  public CONTENT_SERVER = Defaults.SERVER_API_IMAGE + 'qr/menu/product/image/';

  public supplementWrapper: SupplementWrapper;

  public isOpen = false;
  public forcedToClose = false;

  public abstract getProductDetailsComponent(): AbstractProductDetailsComponent;

  protected constructor() {
    super();
  }

  public init(): void {
    this.getProductDetailsComponent().setSelectSupplementComponent(this);
  }

  public close(): void {
    this.supplementWrapper = null;
    this.isOpen = false;
  }

  public submit(): void {
    this.close();
  }

  justClose(): void {
    this.forcedToClose = true;
  }

  justOpen(): void {
    this.forcedToClose = false;
  }

  public open(supplementWrapper: SupplementWrapper): void {
    this.supplementWrapper = supplementWrapper;
    this.isOpen = true;
  }

  public selectProduct(productId: string): void {
    this.supplementWrapper.selectedProductId = productId;
    setTimeout(() => {
      this.close();
    }, 250);
  }

}
