import {Component} from "@angular/core";
import {ApplicationStateService} from "../../services/ApplicationStateService";

@Component({
  selector: 'app-order-menu-component-holder',
  templateUrl: './menu.component.holder.component.html',
  styleUrls: ['./menu.component.holder.component.css']
})
export class MenuComponentHolderComponent {

  constructor(public stateService: ApplicationStateService) {

  }

}
