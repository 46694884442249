import {Component, Input, OnInit} from '@angular/core';
import {Table} from "../../domain/table";
import {AbstractMenuComponent} from "../menu/abstract.menu.component";
import {Shop} from "../../domain/shop";
import {Defaults} from "../../constants/defaults";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() menuComponent: AbstractMenuComponent;
  @Input() public shop: Shop;
  @Input() public table: Table;
  @Input() orderItemCount: number;

  @Input() public useBasket: boolean;

  public closedAlertOpen = true;

  public CONTENT_SERVER = Defaults.SERVER_API_IMAGE + 'qr/menu/logo/';

  constructor() { }

  ngOnInit(): void {
  }

}
