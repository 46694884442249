import {MenuOrderTicket} from "../../domain/MenuOrderTicket";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Defaults} from "../../constants/defaults";
import {RedirectResult} from "../../domain/RedirectResult";
import {Router} from "@angular/router";
import {MenuDelivery} from "../../domain/MenuDelivery";
import {AbstractMenuComponent} from "../menu/abstract.menu.component";
import {Shop} from "../../domain/shop";
import {MenuOrderItem} from "../../domain/MenuOrderItem";
import {IHaveMyHttpHandler} from "../../handlers/http/my-http-handler/IHaveMyHttpHandler";
import {Menu} from "../../domain/menu";
import {AbstractDeliverySelectComponent} from "./AbstractDeliverySelectComponent";

export abstract class AbstractDetailComponent extends IHaveMyHttpHandler {
  public selectDeliveryComponent: AbstractDeliverySelectComponent;

  private orderTicketId: string;
  private qrClientToken: string;

  public isOpen = false;

  public shop: Shop;
  public menu: Menu;
  public menuDeliveries: MenuDelivery[];
  public orderTicket: MenuOrderTicket;

  public specifiedDeliverTime = false;
  public deliverTime: string;

  public payAfter = true;

  protected constructor(private http: HttpClient, private router: Router) {
    super();
  }

  public init(): void {
    this.getMenuComponent().setOrderDetailComponent(this);

    const date = new Date();
    this.deliverTime = date.toLocaleTimeString().substring(0, 5);
    console.log(this.deliverTime);
    console.log(date.toLocaleTimeString());
  }

  protected abstract getMenuComponent(): AbstractMenuComponent;

  public open(orderTicketId: string, qrClientToken: string, deliveryTownships: MenuDelivery[], orderTicket: MenuOrderTicket, shop: Shop, menu: Menu): void {
    this.orderTicketId = orderTicketId;
    this.qrClientToken = qrClientToken;

    this.shop = shop;
    this.menu = menu;
    this.menuDeliveries = deliveryTownships;
    this.orderTicket = orderTicket;

    if ( this.orderTicket.note == null ) {
      this.orderTicket.note = '.';
    }

    this.isOpen = true;
  }

  public setOrderTicket(orderTicket: MenuOrderTicket): void {
    this.orderTicket = orderTicket;
  }

  public orderItemPlus(orderItem: MenuOrderItem): void {
    this.changeOrderItemCount(orderItem, "orderItemPlus");
  }

  public orderItemMin(orderItem: MenuOrderItem): void {
    this.changeOrderItemCount(orderItem, "orderItemMin");
  }

  private changeOrderItemCount(orderItem: MenuOrderItem, direction: string): void {
    const headers = new HttpHeaders({
      applicationId: Defaults.applicationId,
      applicationToken: Defaults.applicationToken,
      qrClientToken: this.qrClientToken
    });

    const observable = this.http.get<RedirectResult>(Defaults.SERVER_API + 'qr/order/' + direction + '/' + this.orderTicketId + '/' + orderItem.id, { headers });

    this.myHttpHandlerComponent.get(observable, (result: RedirectResult) => {
      if ( result.url == null ) {
        this.getMenuComponent().getOrderTicket();
      }
      else {
        window.location.replace(result.url);
      }
    });
  }

  public close(reallyClose: boolean): void {
    if ( reallyClose && !this.router.navigateByUrl(this.orderTicketId + '/' + this.qrClientToken + '/na') ) {
      alert('Failed to open order list');
    }
    else {
      this.isOpen = false;
    }
  }

  public justOpen(): void {
    this.isOpen = true;
  }

  public justClose(): void {
    this.isOpen = false;
  }

  public submit(): void {
    if ( this.orderTicket == null ) {
      alert("Er ging iets fout, herlaad de pagina en probeer aub opnieuw");
    }

    const headers = new HttpHeaders({
      applicationId: Defaults.applicationId,
      applicationToken: Defaults.applicationToken,
      qrClientToken: this.qrClientToken,
      deliverTime: this.specifiedDeliverTime? this.deliverTime: '-1',
      payAfter: this.payAfter? 'true': 'false'
    });

    console.log(this.deliverTime);

    this.myHttpHandlerComponent.get<RedirectResult>(this.http.post<RedirectResult>(Defaults.SERVER_API + 'qr/order/placeOrder/' + this.orderTicketId, this.orderTicket, { headers }), (result: RedirectResult) => {
      if ( result.url == null ) {
        this.router.navigateByUrl('order/status/' + this.orderTicketId + '/' + this.qrClientToken);
        this.getMenuComponent().getOrderTicket();
      }
      else {
        window.location.replace(result.url);
      }
    });
  }

  public getMenuDelivery(): MenuDelivery {
    if ( this.menuDeliveries == null ) {
      return null;
    }

    for ( const menuDelivery of this.menuDeliveries ) {
      if ( this.orderTicket.menuDeliveryId == menuDelivery.id ) {
        return menuDelivery;
      }
    }

    return null;
  }

  public getDeliveryCost(): number {
    if ( this.orderTicket.destination == 'D' ) {
      const menuDelivery = this.getMenuDelivery();
      if ( menuDelivery != null ) {
        return menuDelivery.deliveryCost;
      }
    }

    return 0;
  }

  public getDeliveryAllowed(): boolean {
    if ( this.orderTicket == null || this.orderTicket.orderItems == null || this.orderTicket.orderItems.length < 1 ) {
      return false;
    }

    const menuDelivery = this.getMenuDelivery();
    if ( menuDelivery != null ) {
      return menuDelivery.mode == 'C' || this.orderTicket.price >= menuDelivery.minForFreeDelivery;
    }

    return false;
  }

  public isFree(): boolean {
    const menuDelivery = this.getMenuDelivery();
    if ( menuDelivery != null ) {
      return this.orderTicket.price >= menuDelivery.minForFreeDelivery;
    }

    return false;
  }

  public setSelectDeliveryComponent(selectDeliveryComponent: AbstractDeliverySelectComponent): void {
    this.selectDeliveryComponent = selectDeliveryComponent;
  }

}
