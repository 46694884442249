<app-header [menuComponent]="this" [shop]="shop" [table]="table" [orderItemCount]="orderTicket != null? orderTicket.orderItems.length: 0" [useBasket]="true"></app-header>

<div class="category-header" id="category-header">
  <div class="category-header-slider" style="padding: 0 50%" id="category-header-slider">
    <div class="category-header-item" id="{{category.id}}" *ngFor="let category of categories" onclick="headerCategorySelected(event)">
      <h3 class="category-header-item-text">{{category.details[0].name}}</h3>
    </div>
  </div>
</div>
<div class="a">
  <div id="slideContainer" class='container' ontouchstart="mytouchstart(event)" ontouchmove="mytouchmove(event)" ontouchend="mytouchend(event)"><!--  -->
    <div class="item" *ngFor="let category of categories">
      <div class="category">

        <ng-container *ngIf="category != null && category.products != null">
          <ng-container *ngFor="let product of category.products">
            <ng-container *ngIf="product != null && product.active === 'A'">

              <div class="product product-{{category.columnCountQR}}" (click)="openProductDetail(product)">
                <div class="product-image product-image-{{category.showProductImagesQR}}" style="background-image: url('{{CONTENT_SERVER}}{{category.showProductImagesQR}}/128/{{product.productImageId}}.png')">
                </div>
                <div class="product-details">
                  <p class="product-name text-one-line"><ng-container *ngIf="product.details != null && product.details.length > 0">{{product.details[0].name}}</ng-container></p>
                  <ng-container *ngIf="category.columnCountQR == 1"><i class="product-description text-one-line">{{product.details[0].description}}</i></ng-container>
                  <b class="product-price text-one-line">{{product.price / 100 | currency:'EUR'}}</b>
                </div>

                <ng-container *ngIf="product.orderCount > 0">
                  <div class="product-count-dot">
                    <p class="product-count">{{product.orderCount}}</p>
                  </div>
                </ng-container>

              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <div><!-- TODO plaats in component -->
          <div class="footer-logo" (click)="shopDetailsComponent.open()">
            <h3>BESTELMANDJE.BE</h3>
            <p><i>Tik hier voor info</i></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-product-details-mobile [menuComponent]="this"></app-product-details-mobile>
<app-order-detail-mobile [menuComponent]="this"></app-order-detail-mobile>
<app-shop-details-mobile [menuComponent]="this"></app-shop-details-mobile>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
