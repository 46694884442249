<div class="product-popup-background" [ngClass]="{'popup-open': isOpen}" (click)="close()"></div>
<div class="product-popup-close" [ngClass]="{'popup-open': isOpen}" (click)="close()">
  <div class="close">
    <p class="close-text">SLUIT</p><img class="close-icon" src="../../../../assets/icons/delete_icon.png"/>
  </div>
</div>
<div class="product-popup-box" [ngClass]="{'popup-open': isOpen}">
  <ng-container *ngIf="supplementWrapper != null && supplementWrapper.supplementProductWrappers != null">

    <div class="full">
      <h3>{{supplementWrapper.productSupplement.supplement.details[0].name}}<ng-container *ngIf="true"><sup>*</sup></ng-container></h3>
      <p>{{supplementWrapper.productSupplement.supplement.details[0].description}}</p>
    </div>

    <div class="full">

      <ng-container *ngIf="supplementWrapper.productSupplement.supplement.required === 'N'">
        <div class="product-size-box" [ngClass]="{'supplement-selected': supplementWrapper.selectedProductId === null}" (click)="selectProduct(null)">
          <div class="supplement-product-image">
          </div>
          <div class="product-details">
            <h3 class="product-name text-one-line"><ng-container>{{supplementWrapper.productSupplement.supplement.details[0].emptyOptionName}}</ng-container></h3>
            <p class="product-description text-one-line"><i>{{supplementWrapper.productSupplement.supplement.details[0].emptyOptionDescription}}</i></p>
            <ng-container *ngIf="supplementWrapper.productSupplement.supplement.mode === 'F'"><p class="option-price">{{0 | currency: 'EUR'}}</p></ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="supplementWrapper.supplementProductWrappers != null">
        <ng-container *ngFor="let supplementProductWrapper of supplementWrapper.supplementProductWrappers">
          <div class="product-size-box" [ngClass]="{'supplement-selected': supplementWrapper.selectedProductId === supplementProductWrapper.supplementProduct.productId}" (click)="selectProduct(supplementProductWrapper.supplementProduct.productId)">
            <div class="supplement-product-image" style="background-image: url('{{CONTENT_SERVER}}4/64/{{supplementProductWrapper.supplementProduct.product.productImageId}}.png')">
            </div>
            <div class="product-details">
              <h3 class="product-name text-one-line"><ng-container *ngIf="supplementProductWrapper.supplementProduct.product.details != null && supplementProductWrapper.supplementProduct.product.details.length > 0">{{supplementProductWrapper.supplementProduct.product.details[0].name}}</ng-container></h3>
              <p class="product-description text-one-line"><i>{{supplementProductWrapper.supplementProduct.product.details[0].description}}</i></p>
              <ng-container *ngIf="supplementWrapper.productSupplement.supplement.mode === 'P' || supplementWrapper.productSupplement.supplement.mode === 'D'"><p class="option-price">+{{productDetailsComponent.calcPrice(supplementWrapper.productSupplement.supplement, supplementProductWrapper.supplementProduct.product) / 100 | currency: 'EUR'}}</p></ng-container>
              <ng-container *ngIf="supplementWrapper.productSupplement.supplement.mode === 'F'"><p class="option-price">Free</p></ng-container>
            </div>

          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="full">
      <p class="button-close-product" (click)="close()">CLOSE</p>
    </div>
  </ng-container>
</div>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
