import {ProductExtra} from "./ProductExtra";

export class ProductExtraWrapper {
  public productExtra: ProductExtra;

  public value: boolean;

  public click(productExtraWrappers: ProductExtraWrapper[]): void {
    this.value = !this.value;

    if ( this.productExtra.condiment.similarOptionUniqueMaker != null && this.productExtra.condiment.similarOptionUniqueMaker != '' ) {
      for ( const productExtraWrapper of productExtraWrappers ) {
        if ( this.productExtra.condiment.similarOptionUniqueMaker === productExtraWrapper.productExtra.condiment.similarOptionUniqueMaker ) {
          productExtraWrapper.value = this.value;
        }
      }
    }
  }
}
