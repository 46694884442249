import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Defaults} from "../../constants/defaults";
import {MenuOrderTicket} from "../../domain/MenuOrderTicket";
import {Menu} from "../../domain/menu";
import {MenuHandler} from "../../handlers/MenuHandler";
import {MenuTableObject} from "../../domain/MenuTableObject";
import {Table} from "../../domain/table";
import {ActivatedRoute} from "@angular/router";
import {Shop} from "../../domain/shop";

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {
  private orderTicketId: string;
  private qrClientToken: string;

  public shop: Shop;
  public table: Table;
  public menu: Menu;
  public orderTicket: MenuOrderTicket;

  private pusher: any;

  constructor(private route: ActivatedRoute, private http: HttpClient) { }

  ngOnInit(): void {
    this.orderTicketId = this.route.snapshot.paramMap.get('orderTicketId');
    this.qrClientToken = this.route.snapshot.paramMap.get('qrClientToken');

    this.getMenu();
  }

  private getMenu(): void {
    const headers = new HttpHeaders({
      applicationId: Defaults.applicationId,
      applicationToken: Defaults.applicationToken,
      qrClientToken: this.qrClientToken
    });

    this.http.get<MenuTableObject>(Defaults.SERVER_API + 'qr/menu/byOrderTicketId/' + this.orderTicketId, { headers }).subscribe(
      (result: MenuTableObject) => {
        this.shop = result.shop;
        this.table = result.table;
        this.menu = result.menu;

        this.setupPusher();
        this.getOrderTicket();
      }, (error) => {

      }, () => {

      }
    );
  }

  private setupPusher(): void {
    // TODO zorg ervoor dat pusher wel gekend is
    // @ts-ignore
    this.pusher = new Pusher('002883e435e9ef4ed05b', {
      cluster: 'eu'
    });

    const statusComponent = this;

    const channel = this.pusher.subscribe(this.orderTicketId);
    channel.bind('EVENT_ORDER_UPDATE', function(data) {
      statusComponent.getOrderTicket();
    });
  }

  public getTime(time: string): string {
    const confirmedDate = new Date(time);
    return confirmedDate.toLocaleTimeString().substring(0, 5);
  }

  private setTimer(time: number): void {
    const statusComponent = this;

    setTimeout(function() {
      statusComponent.getOrderTicket();
    }, time);
  }

  public getOrderTicket(): void {
    const headers = new HttpHeaders({
      applicationId: Defaults.applicationId,
      applicationToken: Defaults.applicationToken,
      qrClientToken: this.qrClientToken
    });

    this.http.get<MenuOrderTicket>(Defaults.SERVER_API + 'qr/order/myTicket/' + this.orderTicketId, { headers }).subscribe(
      (result: MenuOrderTicket) => {
        this.orderTicket = result;

        if ( result.confirmedDeliveryTime != null && result.confirmTimeReceiveTime == null ) {
          this.confirmConfirmReceived();
        }

        MenuHandler.linkOrders(this.menu, this.orderTicket);
        if ( result.deliverTime == null ) {
          if ( result.onDeliveryTime == null ) {
            if ( result.kitchenTime == null ) {
              if ( result.confirmedDeliveryTime == null ) {
                this.setTimer(30e3);
              }
              else {
                this.setTimer(60e3);
              }
            }
            else {
              this.setTimer(120e3);
            }
          }
          else {
            this.setTimer(120e3);
          }
        }
      }, (error) => {
        this.setTimer(30e3);
      }, () => {

      }
    );
  }

  private confirmConfirmReceived(): void {
    const headers = new HttpHeaders({
      applicationId: Defaults.applicationId,
      applicationToken: Defaults.applicationToken,
      qrClientToken: this.qrClientToken
    });

    this.http.get<boolean>(Defaults.SERVER_API + 'qr/order/confirmConfirmReceived/' + this.orderTicketId, { headers }).subscribe(
      (result: boolean) => {
        console.log('Order confirm time receive confirmed successfully');
      }, (error) => {

      }, () => {

      }
    );
  }

}
