import {Component, Input, OnInit} from '@angular/core';
import {AbstractSelectSupplementComponent} from "../abstract-select-supplement.component";
import {AbstractProductDetailsComponent} from "../../product-details/abstract-product-details.component";

@Component({
  selector: 'app-select-supplement-desktop',
  templateUrl: './select-supplement-desktop.component.html',
  styleUrls: ['./select-supplement-desktop.component.css']
})
export class SelectSupplementDesktopComponent extends AbstractSelectSupplementComponent {

  @Input() productDetailsComponent: AbstractProductDetailsComponent;

  constructor() {
    super();
  }

  getProductDetailsComponent(): AbstractProductDetailsComponent {
    return this.productDetailsComponent;
  }
}
